$(document).ready(function () {
  if (window.location.pathname == '/integration/2' && $("#auth_bitrix24").length) {
    var child_window,
        timer;
    $("#auth_bitrix24").on('click', function () {
      var $this    = $(this),
          domain   = $this.data('domain'),
          app      = $this.data('app');
      child_window = window.open('https://' + domain + '/oauth/authorize/?client_id=' + app, '_blank', 'toolbar=0,location=0,menubar=0,width=500,height=800');

      timer = setInterval(checkChild, 500);
    });

    function checkChild() {
      if (child_window.closed) {
        console.log('location.reload')
        location.reload();
        clearInterval(timer);
      }
    }
  }
});


function integrations() {
  this.switch = function (self, type, enable, params) {
    if ($(self).attr('disabled')) {
      return;
    }
    if (!enable) {
      Confirm('Отключить?', function () {
        switchCall(self, type, enable);
      });
    }
    else {
      if (params.active) {
        switchCall(self, type, enable);
      }
      else {
        var functionId = $(self).attr('data-function_id');
        switchFunction(self, 'add', {id: functionId, count: 1}, {reload: true, confirm: true});
      }
    }

    function switchCall(self, type, enable) {
      $(self).attr('disabled', 'disabled');
      $.send('/integration/switch/' + type, {}, {
        method: 'get', done: function () {
          if (enable) {
            document.location = '/integration/' + type;
          }
          else {
            document.location = '/integration';
          }
        }
      });
    }
  };
}
