function numbersOtherNumberSave() {
  if ($('.numbers-other-send').loading()) {
    return false;
  }

  var forward = $('#forward-' + $('#forward').val()).val();
  var id      = $('#number_id').val();
  $.send('/numbers/other/save/' + (id || 0), {
    name       : $('#name').val(),
    server     : $('#server').val(),
    port       : $('#port').val(),
    noreg      : $('#noreg').prop('checked') ? false : true,
    proxy_addr : $('#proxy_need').prop('checked') ? $('#proxy_addr').val() : null,
    proxy_user : $('#proxy_need').prop('checked') ? $('#proxy_user').val() : null,
    login      : $('#login').val(),
    password   : $('#password').val(),
    forward    : $('#forward').val(),
    forward_to : forward
  }, function() {
    location.reload();
  });
}

function numbersOtherNumberAdd() {
  $('#number_id').val('');
  $('#name').val('');
  $('#name').removeAttr('disabled');
  $('#server').val('');
  $('#login').val('');
  $('#password').val('');
  $('#port').val('5060');
  $('#proxy_need').prop('checked', false);
  $('#proxy_need').prop('checked', false).icheck('unchecked');
  $('#noreg').prop('checked', false).icheck('unchecked');
  $('#proxy_params').hide();
  $('.numbers-other-form').removeClass('hide');
  $('#noreg_ip').hide();
  handlePasswordFields();
}

function numbersOtherNumberEdit(id) {
  var params = $('#number_' + id).data('params');
  console.log(params);
  $('#number_id').val(id);
  $('#name').val(params.name);
  $('#name').attr('disabled', 'disabled');
  $('#server').val(params.server);
  $('#login').val(params.login);
  $('#port').val(params.port);
  $('#proxy_need').prop('checked', false).icheck('unchecked');
  $('#noreg').prop('checked', false).icheck('unchecked');

  $('#proxy_addr').val(params.proxy_addr);
  $('#proxy_user').val(params.proxy_username);
  $('#proxy_params').hide();

  if (params.register_need == 't') {
    $('#noreg').prop('checked', false).icheck('unchecked');
    $('#noreg_ip').hide();
  } else {
    $('#noreg').prop('checked', true).icheck('checked');
    $('#noreg_ip').show();
  }

  if (params.proxy_addr != null && params.proxy_addr != '') {
    $('#proxy_need').prop('checked', true).icheck('checked');
    $('#proxy_params').show();
  }
  $('#password').val(params.pass);
  $('#forward').val(params.action_id).selectmenu('refresh');
  $('#forward-' + params.action_id).val(params.action_option);
  var $select = $('#forward-' + params.action_id);
  if ($select.length && $select.get(0).tagName == 'SELECT') {
    $('#forward-' + params.action_id).selectmenu('refresh');
  }
  $('.forward').addClass('hide');
  $('.forward-' + params.action_id).removeClass('hide');

  $('.numbers-other-form').removeClass('hide');
}

function numbersOtherNumberDelete(id) {
  if (Confirm('Удалить номер?', function() {
    if ($('.numbers-other-send').loading()) {
      return false;
    }

    $.send('/numbers/other/delete/' + id, {}, function() {
      location.reload();
    });
  }));
}

function getNumbersStatuses() {
  $.send('/numbers/other/statuses', {}, function(data) {
    if (data.length > 0) {
      data.forEach(function(item) {
        var css = 'warning';
        var com = 'Ожидает подтверждения';
        if (item.status == 'Registered') {
          css = 'success';
          com = 'Зарегистрирован';
        }
        if (item.status == 'Request_Sent') {
          css = 'warning';
          com = 'Ожидает подтверждения';
        }
        if (item.status == 'No_Authentication') {
          css = 'danger';
          com = 'Не авторизован';
        }
        $('#sts_'+item.number).removeClass(function() {
          return $( this ).attr( "class" );
        });
        $('#sts_'+item.number).addClass('point point-' + css);
        $('#sts_t_'+item.number).html(com);
      });
    }
  });
}

$(document).ready(function() {
  if (document.location.pathname == '/numbers/other') {
    getNumbersStatuses();
    $('#forward').change(function() {
      $('li.forward').addClass('hide');
      $('li.forward-' + $(this).val()).removeClass('hide');
    });
  }
});

$('#proxy_need').click(function() {
  if ($(this).prop('checked') == true) {
    $('#proxy_params').show();
  } else {
    $('#proxy_params').hide();
  };
});

$('#noreg').click(function() {
  if ($(this).prop('checked') == true) {
    $('#noreg_ip').show();
  } else {
    $('#noreg_ip').hide();
  };
});

$('#proxy_need').change(function() {
  console.log('change');
});