$(document).ready(function () {

  var modal_requests = $('#widget_phone_requests');

  $("body").on('click', '.js-remove_request_widget', function () {
    var $this      = $(this),
        request_id = $this.data('id'),
        widget_id  = $this.data('widget_id'),
        row_del    = $this.closest('.widget_block_info_request');


    $.ajax({
      type   : 'GET',
      url    : '/widgets_phone/requests/del/' + request_id,
      success: function (data) {
        row_del.remove();
        $(".js-show_requests_widget[data-id=" + widget_id + "]").html($(".widget_block_info_request").length);
      }
    });
  });

  $(".js-show_requests_widget").on('click', function () {
    var $this       = $(this),
        widget_id   = $this.data('id'),
        widget_name = $this.closest('tr').children(0).children().html();

    $.ajax({
      type   : 'GET',
      url    : '/widgets_phone/requests/' + widget_id,
      success: function (data) {
        $("#widget_phone_name_modal").html(widget_name);
        $("#widget_phone_container_modal").html(data);

        makeModal({
          id      : '#widget_phone_requests-modal',
          messages: $('<div>').append(modal_requests.removeClass('hide')).html(),
          events  : [
            {
              name: 'hidden.bs.modal',
              func: function () {
                $("#widget_phone_container_modal").empty();
                $("#widget_phone_name_modal").empty();
                $('body').append(modal_requests);
                modal_requests.addClass('hide')
                $('#widget_phone_requests-modal').remove();
                return false;
              }
            },
            {
              name: 'show.bs.modal',
              func: function () {
                if ($("#widget_phone_container_modal").find('.widget_block_info_request').length) {
                  $("#export_excel").show();
                  $("#export_excel").attr('href', '/widgets_phone/requests/' + widget_id + '/xls');
                } else {
                  $("#export_excel").hide();
                }
                return false;
              }
            }
          ]
        });
      }
    });
  });


});

function show_create_widget() {
  var form = $('#widget_phone_create');

  makeModal({
    id      : '#widget_phone_create-modal',
    messages: $('<div>').append(form.removeClass('hide')).html(),
    buttons : [{
      text: 'Сохранить', handler: function () {
        $('#emp_access-dialog-modal').modal('hide');
        $.ajax({
          type      : 'POST',
          dataType  : 'json',
          url       : '/widgets_phone/create/',
          data      : {
            'name': $('#name_phone_widget').val()
          },
          beforeSend: function () {
            $('#widget_phone_create-modal a.btn.btn-success.btn_w225').prop('disabled', true).html('Отправка...');
          },
          success   : function (data) {
            if (data.result === 'success') {
              location.reload();
            }
          },
          error     : function (data) {
            alert('Произошла ошибка.');
          }
        });
        return false;
      }
    }],

    events: [
      {
        name: 'hidden.bs.modal', func: function () {
        $('body').append(form);
        $('#widget_phone_create-modal').remove();
        return false;
      }
      }
    ]
  });
}

function remove_widget(id) {
  makeModal({
    id     : '#widget_phone_trash-modal',
    title  : 'Вы уверены что хотите удалить виджет?',
    buttons: [{
      text: 'Удалить', handler: function () {
        $.ajax({
          type    : 'POST',
          dataType: 'json',
          url     : '/widgets_phone/trash/' + id,
          success : function (data) {
            if (data.result === 'success') {
              location.reload();
            }
          }
        });
        return false;
      }
    }]
  });
}

function trigger_widget(id, active) {
  makeModal({
    id     : '#widget_phone_trigger-modal',
    title  : 'Вы уверены что хотите ' + ($('#widget_phone_trigger_' + id).children().data('active') === 't' ? 'отключить' : 'включить') + ' виджет?',
    buttons: [{
      text   : ($('#widget_phone_trigger_' + id).children().data('active') === 't' ? 'Отключить' : 'Включить'),
      handler: function () {
        $.ajax({
          type    : 'POST',
          dataType: 'json',
          url     : '/widgets_phone/trigger/' + id,
          success : function (data) {
            $('#widget_phone_trigger-modal').modal('hide');
            if (data.result === 'success') {
              $('#widget_phone_trigger_' + id).css('color', (data.active === true ? 'green' : 'red'));
              $('#widget_phone_trigger_' + id).children().data('active', (data.active === true ? 't' : 'f'));
            }
          }
        });
        return false;
      }
    }]
  });
}

function copyToClipboardCodeWidget(el_click, element) {
  // var $temp = $("<input id='copy_input_data' style='opacity: 0;' />");
  // $("body").append($temp);
  // $temp.val($(element).val()).select();
  // document.execCommand("copy");
  // $(el_click).attr('title', 'Код скопирован!');
  // $(el_click).tooltip('fixTitle').tooltip('show');
  var modal_copy = $("#copy_code_widget_phone");
  $("#copy_code_widget_phone_textarea").text($(element).val());

  makeModal({
    id      : '#copy_code_widget_phone-modal',
    messages: $('<div>').append(modal_copy.removeClass('hide')).html(),
    events  : [
      {
        name: 'hidden.bs.modal',
        func: function () {
          $('body').append(modal_requests);
          return false;
        }
      }
    ]
  });
}

function revertTitleCopyWidget(el_click) {
  $(el_click).attr('title', 'Копировать код виджета');
  $(el_click).tooltip('fixTitle').tooltip('hide');
}