function audioFiles() {
  this.makeEditModal = function(id, name) {
    var $form = $('<form id="audio-file-edit-form"></form>'),
      $formGroup = $('<div class="form-group validate-entity-audio-file"></div>');
    $formGroup.append('<label for="file-name" class="control-label">Название файла</label>');
    $formGroup.append('<input id="file-name" name="file_name" type="text" class="form-control" value="' + name + '">');
    $formGroup.append('<span class="help-block" validate="file-name"></span>');
    makeModal({
      id       : '#audio-file-edit-modal',
      title    : "Редактирование файла",
      messages : $form.append($formGroup).get(0).outerHTML,
      buttons  : [{text:"Сохранить", handler: function(){
        var name = $('#audio-file-edit-modal form input[name=file_name]').val();
        $('#audio-file-edit-modal a.btn').loading();
        $.send('/audio_files/save/' + id, {name: name}, function(result) {
          if (result.ok) {
            $('#audio-file-edit-modal').modal('hide');
            location.reload();
          }
        });
      }}]
    });
  };
  this.deleteFile = function(id, self) {
    $.clearError();
    Confirm('Вы уверены?', function() {
      $.send('/audio_files/delete/' + id, {}, function(result) {
        if (result.invalid) {
          $.setError(result.invalid);
        }
        else {
          $(self).parents('tr').remove();
        }
      });
    });
  };
  this.addFileForm = function(self) {
    makeModal({
      id       : '#audio-file-add-modal',
      title    : "Добавление аудиофайла",
      messages : $(self).data("modal"),
      buttons  : [{text:"Сохранить", handler: function() {
        var files = $('#audio-file-add-modal form input[name=select_file]').get(0).files,
          formData = new FormData(),
          name = $('#audio-file-add-modal form input[name=file_name]').val();;
        if ( !files.length ) {
          $('#audio-file-add-modal').modal('hide');
          return;
        }
        $('#audio-file-add-modal a.btn').loading();
        formData.append('file', files[0], files[0].name);
        $.send('/audio_files/save', formData, {
          processData: false,
          contentType: false,
          success: function (result) {
            if (!result.ok) {
              return;
            }
            if (name) {
              $.send('/audio_files/save/' + result.id, {name: name}, function(result) {
                if (result.ok) {
                  $('#audio-file-add-modal').modal('hide');
                  location.reload();
                }
              });
            }
            else {
              location.reload();
            }
          }
        });
      }}]
    });
  }
  this.play = function(fileId) {

  }
  /**
   * Обработчик
   * @author Yury Lugovoy
   */
  this.handler = function() {
    /**
     * Инициализация плеера
     * @author Yury Lugovoy
     * @param el
     * @param mediaObjects
     */
    initJPlayer = function(el, mediaObjects){
      var myPlayer = $(el),
        myPlayerData,
        fixFlash_mp4, // Flag: The m4a and m4v Flash player gives some old currentTime values when changed.
        fixFlash_mp4_id, // Timeout ID used with fixFlash_mp4
        ignore_timeupdate, // Flag used with fixFlash_mp4
        options = {
          ready: function (event) {
            // Hide the volume slider on mobile browsers. ie., They have no effect.
            if(event.jPlayer.status.noVolume) {
              // Add a class and then CSS rules deal with it.
              $(".jp-gui").addClass("jp-no-volume");
            }
            // Determine if Flash is being used and the mp4 media type is supplied. BTW, Supplying both mp3 and mp4 is pointless.
            fixFlash_mp4 = event.jPlayer.flash.used && /m4a|m4v/.test(event.jPlayer.options.supplied);
            // Setup the player with media.
            $(this).jPlayer("setMedia", mediaObjects);
          },
          timeupdate: function(event) {
            if(!ignore_timeupdate) {
              myControl.progress.slider("value", event.jPlayer.status.currentPercentAbsolute);
            }
          },
          volumechange: function(event) {
            if(event.jPlayer.options.muted) {
              myControl.volume.slider("value", 0);
            } else {
              myControl.volume.slider("value", event.jPlayer.options.volume);
            }
          },
          swfPath: "../js",
          supplied: "mp3, m4a, oga",
          cssSelectorAncestor: "#jp_container",
          wmode: "window",
          keyEnabled: true
        },
        myControl = {
          progress: $(options.cssSelectorAncestor + " .jp-progress-slider"),
          volume: $(options.cssSelectorAncestor + " .jp-volume-slider")
        };
      // Instance jPlayer
      myPlayer.jPlayer(options);
      // A pointer to the jPlayer data object
      myPlayerData = myPlayer.data("jPlayer");
      // Create the progress slider control
      myControl.progress.slider({
        animate: "fast",
        max: 100,
        range: "min",
        step: 0.1,
        value : 0,
        slide: function(event, ui) {
          var sp = myPlayerData.status.seekPercent;
          if(sp > 0) {
            // Apply a fix to mp4 formats when the Flash is used.
            if(fixFlash_mp4) {
              ignore_timeupdate = true;
              clearTimeout(fixFlash_mp4_id);
              fixFlash_mp4_id = setTimeout(function() {
                ignore_timeupdate = false;
              },1000);
            }
            // Move the play-head to the value and factor in the seek percent.
            myPlayer.jPlayer("playHead", ui.value * (100 / sp));
          } else {
            // Create a timeout to reset this slider to zero.
            setTimeout(function() {
              myControl.progress.slider("value", 0);
            }, 0);
          }
        }
      });
      // Create the volume slider control
      myControl.volume.slider({
        animate: "fast",
        max: 1,
        range: "min",
        step: 0.01,
        value : $.jPlayer.prototype.options.volume,
        slide: function(event, ui) {
          myPlayer.jPlayer("option", "muted", false);
          myPlayer.jPlayer("option", "volume", ui.value);
        }
      });
    }
    $(document).ready(function(){
      if($(document).find('#jquery_jplayer')){
        initJPlayer("#jquery_jplayer", {});
      }
    })
  };

  /**
   * Модалка с плеером
   * @author Yury Lugovoy
   */
  this.showPlayFormModal = function(fileId){
    var modalBody = '<div id="jquery_jplayer_modal_body"></div>',
      text = $('#text').val();
    makeModal({
      id      : "#jquery_jplayer_modal",
      title   : "",
      messages: modalBody,
      buttons : false,
      events  : [
        {name:"show.bs.modal",func:function(){
          $("#jquery_jplayer_tpl > div").appendTo("#jquery_jplayer_modal_body");
        }},
        {name:"shown.bs.modal",func:function(){
          $("#jquery_jplayer").jPlayer("setMedia",{mp3: '/audio_files/get/' + fileId}).jPlayer("play");
        }},
        {name:"hide.bs.modal",func:function(){
          $("#jquery_jplayer_modal_body > div").appendTo("#jquery_jplayer_tpl");
          $("#jquery_jplayer").jPlayer("clearMedia");
        }}
      ],
    })
  }
};