function handleGroups() {
  handleFileUploadSelect();
  handleGroupEmployersList();
}

function handleFileUploadSelect() {
  $('.file-select-upload').change(function(){
    var self = this;
    if ($(self).val() == 'upload') {
      $('#tmp_upload_file').remove();
      $(self).after('<input type="file" id="tmp_upload_file">');
      setTimeout(function(){
        $('#tmp_upload_file').change(function(event){
          var file = event.target.files[0];
          var $uploadInput = $(this);
          if ( ! $uploadInput.next('.file-select-upload-data').length) {
            $uploadInput.after('<input class="file-select-upload-name" type="hidden" name="' + $(self).attr('name') + '_name">');
            $uploadInput.after('<input class="file-select-upload-data" type="hidden" name="' + $(self).attr('name') + '_file">');
          }
          $(self).find('option[value="upload"]').text(file.name);
          $(self).selectmenu( "refresh" );
          setTimeout(function(){
            var reader = new FileReader();
            reader.onload = function(e) {
              $uploadInput.parent().find('.file-select-upload-data').val(e.target.result);
              $uploadInput.parent().find('.file-select-upload-name').val(file.name);
              $('#tmp_upload_file').remove();
            };
            reader.readAsDataURL(file);
          }, 0);
        }).click();
      }, 0);
    }
  });
}

function saveGroup(self) {
  var $form = $(self).closest('form');
  var $clone = $form.clone();
  var selects = [
    'define_number_id',
    'calls_order_id',
    'welcome_audio_id',
    'wait_audio_id',
    'action_id',
    'action_option'
  ];
  $clone.find('.hide').remove();
  $form.removeAttr('data-form_changed');
  $form.find('.save-group').loading();
  //Сделано для восстановления value, т.к. clone очищать val у select
  for (var i in selects) {
    if ($('select[name=' + selects[i] + '] option').length > 1) {
      $clone.find('select[name=' + selects[i] + ']').val($form.find(':not(.hide) > select[name=' + selects[i] + ']').val());
    }
  }
  $.send($clone.attr('action'), $clone.serializeArray(), function(result){
    window.location = '/groups';
  });
  return false;
}

function delGroup(self) {
  Confirm('Удалить группу?', function(){
    $('#modal').find('.btn-success').loading();
    $.send('/groups/del/' + $(self).data('id'), {}, function(result){
      $(self).closest('tr').remove();
    });
  });
}

function handleGroupEmployersList() {
  var $employersList = $('#group_employers_list');
  if ($employersList.length) {
    $employersList.find('tbody').empty();
    var selectedIds = $employersList.data('selected');
    var employersList = $employersList.data('list');
    var $template = $('.group-employer-tpl');
    var emp, key, $elem, $tpl;
    for (var i in selectedIds) {
      $tpl = $template.clone();
      $tpl.removeClass('hide');
      emp = employersList[selectedIds[i]];
      for (key in emp) {
        $elem = $tpl.find('.assign-' + key);
        if (key == 'id') {
          $elem.attr('data-id', emp[key]);
          if ($elem.attr('type') == 'hidden') {
            $elem.val(emp[key]);
          }
        }
        else {
          $elem.text(emp[key]);
        }
      }
      $employersList.find('tbody').append($tpl);
    }
  }
}

function addEmployerToGroup(self) {
  var $select = $('.add-group-employer-select');
  var id = $select.val();
  if ( ! id) {
    return false;
  }
  $select.find('option[value=' + id + ']').remove();
  if ( ! $select.find('option').length) {
    $select.append('<option></option>');
  }
  $select.selectmenu( "refresh" );
  var $employersList = $('#group_employers_list');
  var selectedIds = $employersList.data('selected');
  selectedIds.push(id);
  $employersList.data('selected', selectedIds);
  handleGroupEmployersList();
  return false;
}

function removeEmployerFromGroup(self) {
  var id = $(self).data('id');
  var $employersList = $('#group_employers_list');
  var employersList = $employersList.data('list');
  var emp = employersList[id];
  var selectedIds = $employersList.data('selected');
  for (var i in selectedIds) {
    if (selectedIds[i] == id) {
      delete selectedIds[i];
      break;
    }
  }
  $employersList.data('selected', selectedIds);
  var $select = $('.add-group-employer-select');
  $select.find('option:not([value])').remove();
  $select.append('<option value="' + id + '">' + emp.number + ' - ' + emp.name + '</option>');
  $select.selectmenu( "refresh" );
  handleGroupEmployersList();
  return false;
}