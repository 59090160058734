var
  cookieHelp = $.cookie('helpState') || 'off',
  ChartColors = {
    defaults : ['rgba(38, 120, 176, 1)', 'rgba(24, 186, 154, 1)', 'rgba(252, 206, 88, 1)', 'rgba( 2, 170, 214, 1)', 'rgba(236, 68, 76, 1)'],
    hover    : ['rgba(48, 130, 186, 1)', 'rgba(34, 196, 164, 1)', 'rgba(255, 216, 98, 1)', 'rgba(12, 180, 224, 1)', 'rgba(246, 78, 86, 1)']
  },
  audioFilesBlock       = new audioFiles(),
  changeHistoryBlock    = new changeHistory(),
  employersBlock        = new employers(),
  faxBlock              = new fax(),
  scenariosBlock        = new scenarios(),
  voiceSynthesizerBlock = new voiceSynthesizer(),
  storageBlock          = new storage(),
  integrationsBlock     = new integrations();

$(document).ready(function() {
  toggleSidebar();
  toggleBoxEasing();
  handleSwitchForwardings();
  handleChangeNumber();
  handleGeneratePass();
  handleScenario();
  handleFileUploadSelect();
  handleGroups();
  blackListHandler();
  handleEmployersSection();
  handleRememberedAlerts();
  handlePasswordFieldsEvents();
  handleAddEmployerChangeName();
  voiceSynthesizerBlock.handler();
  faxBlock.handler();
  audioFilesBlock.handler();

  if (cookieHelp === 'on') {
    $('.box_easing .box').trigger('click');
  }
  $('.popupover').popover({
    'placement': function(){
      return 'auto';
    }
  });
  checkStatus();
});

/**
* Анимация основного меню
*/
function toggleSidebar(){
  var wrapper = $('#wrapper'),
      menu = $('#menu'),
      submenu = $('#submenu'),
      sidebar = $('#sidebar'),
      menuItems = menu.find('.menu_item'),
      iconMenuRight = menu.find('.icon-menu-right'),
      activeMenuItem = menuItems.filter('.menu_item_active'),
      submenuContents = submenu.find('.submenu_content'),
      activeSubmenuItem = submenu.find('.submenu_item_active'),
      //ширина свернутого меню
      menuSmWidth = '63px',
      //ширина развернутого меню
      menuLgWidth = '260px',
      submenuOpenTimer;
  //разворачиваем основное меню
  var switchMenu = function(name, width){
    //timer для отображения, после задержки мышки в элементе
    submenuOpenTimer = setTimeout(function() {
      //меняем ширину меню
      menu.animate({width:width},200);
      switchSubmenu(name,width);
    }, 200)
  }
  //отображает подменю раздела name
  var switchSubmenu = function(name, width){
    var menuItem = menuItems.filter('[data-name=' + name + ']'),
        submenuItem = submenuContents.filter('.submenu_content_' + name);
    //если ширина свернутого меню sidebar добавляем класс свернутого меню
    if(width==menuSmWidth){
      sidebar.addClass('sidebar_submenu_active');
    } else {
      sidebar.removeClass('sidebar_submenu_active');
    }
    submenuItem.addClass('submenu_content_active');
    submenuContents.not(submenuItem).removeClass('submenu_content_active');
    menuItem.addClass('menu_item_hover');
    menuItems.not(menuItem).removeClass('menu_item_hover');
    if(submenuItem.length){
      submenu.animate({left:width,opacity:1},200);
    } else {
      submenu.animate({left:0,opacity:0},100);
    }
  };
  // при наведении на стрелку или кнопку выхода
  $('#menu .icon-menu-right, #menu .btn-logout').on('mouseenter', function(){
    //разворачиваем меню и подменю активного раздела
    switchMenu(activeMenuItem.data('name'),menuLgWidth);
  });
  //при наведении на пункт меню
  menuItems.on('mouseenter', function(){
    //разворачиваем меню и подменю текщего раздела
    switchMenu($(this).data('name'),menuLgWidth);
  });
  menuItems.on('mouseleave', function(){
    clearTimeout(submenuOpenTimer);
  });
  //при покидании элемента sidebar возвращаем все на место
  sidebar.on('mouseleave', function(){
    if(activeSubmenuItem.length){
      switchMenu(activeMenuItem.data('name'),menuSmWidth);
    } else{
      menuItems.removeClass('menu_item_hover');
      submenu.animate({left:0,opacity:0},200);
    }
  });
}

/**
* Анимация блока вкл/выкл
*/
function toggleBoxEasing(){
  //анимация включения настройки
  var onBoxEasing = function(boxItem, duration){
    var box = boxItem.find('.box'),
        label = boxItem.find('.label'),
        //функция funcSuccess выполняется после анимации
        funcSuccess = window[boxItem.data('func')];
    label.animate({
      //максимальная позиция ползунка = ширина boxItem - ширина label
      left: boxItem.outerWidth(true) - label.outerWidth(true)
    }, ($.isNumeric(duration) ? duration : 300),'easeInCirc', function(){
      box.text('вкл');
      boxItem.removeClass('off').addClass('on');
      //запускаем функцию, указанную в параметре data-func
      if ($.isFunction(funcSuccess)){
        return funcSuccess('on');
      }
    });
  }
  //анимация выключения настройки
  var offBoxEasing = function(boxItem, duration){
    var box = boxItem.find('.box'),
        label = boxItem.find('.label'),
        funcSuccess = window[boxItem.data('func')];
    label.animate({
      left: 0
    }, ($.isNumeric(duration) ? duration : 300),'easeInCirc', function(){
      box.text('выкл');
      boxItem.removeClass('on').addClass('off');
      //запускаем функцию, указанную в параметре data-func
      if ($.isFunction(funcSuccess)){
        return funcSuccess('off');
      }
    });
  }
  //анимация при клике на блок box_easing
  $(document).on('click', '.box_easing .box', function(e){
    var boxItem = $(this).parent();
    if(boxItem.hasClass('on')){
      offBoxEasing(boxItem);
    } else {
      onBoxEasing(boxItem);
    }
  })
  $(document).on('click', 'a.showHelp', function(e){
    $('.box_easing .box').trigger('click');
  });
  $(document).on('click', '.box_easing > div.label', function(e){
    $('.box_easing .box').trigger('click');
  });
  //анимация при нажатии на box_easing в фокусе
  $(document).on('focus', '.box_easing', function(){
    var boxItem = $(this);
     boxItem.on('keyup',function(event){
      //если enter, то переключаем
      if(event.keyCode == 13 && boxItem.hasClass('on')){
        offBoxEasing(boxItem);
      }
      if(event.keyCode == 13 && boxItem.hasClass('off')){
        onBoxEasing(boxItem);
      }
      //если стрелка вправо то включаем
      if(event.keyCode == 39){
        onBoxEasing(boxItem);
      }
      //если стрелка влево то выключаем
      if(event.keyCode == 37){
        offBoxEasing(boxItem);
      }
    })
  })
  //анимация при перетаскивании .label в блоке box_easing
  $(".box_easing .label").draggable({
    containment:'parent',
    stop: function(event, ui){
      var boxItem = $(this).parent(),
          label = boxItem.find('.label'),
          //максимальная позиция ползунка = ширина boxItem - ширина label
          posMax = boxItem.outerWidth(true) - label.outerWidth(true);
      //перетаскиваем ползунок в начало или в конец в зависимости от его положения при остановке
      if(boxItem.hasClass('on') && ui.position.left == 0){
        offBoxEasing(boxItem,0);
      } else if(boxItem.hasClass('on') && ui.position.left < posMax){
        offBoxEasing(boxItem);
      } 
      if(boxItem.hasClass('off') && ui.position.left == posMax){
        onBoxEasing(boxItem,0);
      } else if(boxItem.hasClass('off') && ui.position.left > 0){
        onBoxEasing(boxItem);
      }
    }
  });
}

/**
 * Переключатель подсказок
 */
function toggleHelp(state) {
  var cookieHelp = $.cookie('helpState');
  if ( ! state) {
    state = cookieHelp || 'on';
    $('.box_easing').removeClass('on off');
    $('.box_easing').addClass(state);
    $('.box_easing .box').text(state == 'on' ? 'вкл' : 'выкл');
  }
  if (state == 'off') {
    $('.popover_help').popover('destroy');
  }
  else {
    showHelpPopover();
  }
  $.cookie('helpState', state, {expires:30});
}

/**
* Отображает подсказки на странице
*/
function showHelpPopover(){
  var template = $(document.createElement('div'))
      .attr('role', 'tooltip')
      .addClass("popover popover_help")
      .append($(document.createElement('div')).addClass('arrow'))
      .append($(document.createElement('button'))
        .addClass('close')
        .attr('aria-label', 'Close')
        .append('<span class="glyphicon glyphicon-remove-circle small"></span>')
        .click(function(){
          $(this).parent().popover('hide');
        })
      )
      .append('<div class="popover-content"></div>');

  setTimeout(function(){
    $('.help-popupover[data-content!=""]').each(function(){
      var $tpl = template.clone(true);
      if ($(this).data('class')) {
        $tpl.addClass($(this).data('class'));
      }
      $(this).popover({
        'template'  : $tpl,
        'html'      : true,
        'placement' : ($(this).data('placement') || 'right')
      }).popover('show');
    });
  }, 100);

  $('.popupover').popover({
    'placement': function(){
      return 'auto';
    }
  });
}

/**
* Считает к-во и сумму выбранных тел. номеров
* в форме поиска при покупке
*/
function updateCheckPhones(obj){
  var parent = $(obj).parents('.search_phones_result'),
      phoneItems = parent.find('input[type="checkbox"]:checked'),
      countContainer = parent.find('.count'),
      sumContainer = parent.find('.sum'),
      count = 0,
      sum = 0;
  phoneItems.each(function(key,item){
    count += 1;
    sum = sum+($(item).data('cost')-0);
  });
  countContainer.text(count);
  sumContainer.text(sum);
}

/**
 * Обработать селект изменения переадресации
 */
function handleSwitchForwardings() {
  $(document).on('change', '.switch-forwardings', function(){
    $('.forwarding-no-answer-time')[$.inArray(parseInt($(this).val()), [3,5]) != -1 ? 'removeClass' : 'addClass']('hide');
    $('.switch-forwardings-properties')[$(this).val() == 1 ? 'addClass' : 'removeClass']('hide');
    switchForwardingAction($('.switch-forwardings-actions').get(0));
  });
  $(document).on('change', '.switch-forwardings-actions', function(){
    switchForwardingAction(this);
  });
  $('.switch-forwardings').trigger('change');
  $('.switch-forwardings-actions').trigger('change');
}

/**
 * Переключение действия по переадресации
 */
function switchForwardingAction(self) {
  var names = {
    3: 'Внутренний номер',
    4: 'Группа',
    5: 'Автосекретарь'
  };
  var val = $(self).val();
  if (names[val]) {
    $('.forwarding-option-input').addClass('hide');
    $('.forwarding-option')[$('#forwarding_id').val() == 1 ? 'addClass' : 'removeClass']('hide');
    $('.forwarding-option-'+val).removeClass('hide');
    $('.forwarding-option-name').text(names[val]);
  }
  else {
    $('.forwarding-option').addClass('hide');
  }
}

function handleChangeNumber() {
  $(document).on('keyup', '.entity-number', function(event){
    if ($.inArray(event.keyCode, [16,35,36,37,38,39,40]) != -1) {
      return;
    }
    var self = this;
    var value = event.target.value.toString();
    setTimeout(function(){
      changeEntityNumber(self, value);
    }, 0);
  });
}

function handleGeneratePass() {
  $(document).on('click', '.gen-pass', function(){
    var target = $(this).attr('target');
    if (target) {
      var $target;
      var closestBlock = $(this).attr('closest-block');
      if (closestBlock) {
        $target = $(this).closest(closestBlock).find(target);
      }
      else {
        $target = $(target);
      }
      $target.val(getPass(8));
    }
    return false;
  });
}

/**
 * Обработка алертов для показа только один раз каждому пользователю
 */
function handleRememberedAlerts() {
  $('.alert-remembered').each(function(){
    var id = $(this).data('id');
    if ( ! id) {
      return;
    }
    if ($.cookie('alert_remembered_' + id)) {
      $(this).hide();
    }
    else {
      $(this).css('visibility', 'visible');
      $(this).find('.close').click(function(){
        $.cookie('alert_remembered_' + id, 'true', {expires:7});
      });
    }
  });
}

function changeEntityNumber(self, number) {
  self = self ? self : $('.entity-number').get(0);
  number = number ? number : $(self).val();
  var prefix = $(self).attr('data-prefix');
  var login = $(self).attr('data-login');
  var numberText = prefix + (number[0] == prefix ? number.substring(1, 4) : number.substring(0, 3));
  $(self).val(numberText);
  var format = $('.requisits-table').attr('data-format');
  number = '000' + number;
  login = login + prefix + number.substring(number.length - 3);
  $('.sip-user-id').text(format == 'asterisk' ? login : numberText);
  $('.entity-terminal-login').val(login);
  var i = 1;
  $('.terminals-login:visible input').each(function(){
    $(this).val(login + i.toString());
    i++;
  });
}

/**
 * Показывать иконку показа пароля в читаемом виде для инпутов с классом switch-show-pass
 */
function handlePasswordFieldsEvents() {
  handlePasswordFields();
  $(document).on('click', '.show-pass-icon', function(){
    var input = $(this).data('input');
    $(input).attr('type', $(input).attr('type') == 'password' ? 'text' : 'password');
  });
}

function handlePasswordFields() {
  $('.switch-show-pass:visible').each(function(){
    var rect = $(this).pos();
    var pos = $(this).position();
    if ($(this).data('input')) {
      return;
    }
    var hover = document.createElement("div");
    hover.setAttribute('class', 'show-pass-icon glyphicon-eye-open');
    hover.setAttribute('style', 'left:' + (pos.left + rect.width - 25) + 'px;top:' + pos.top + 'px;line-height:' + rect.height + 'px');
    $(hover).data('input', this);
    $(this).after(hover);
  });
}

/**
 * Клик по кнопке "Нашли ошибку"
 */
$('#report-error-btn').click(function() {
  makeModal({
    id   : '#modal-error',
    title: 'Сообщение об ошибке',
    messages: ['<textarea name="text" placeholder="Текст сообщения" class="form-control"></textarea>'],
    buttons: [
      {
        'text'      : 'Отправить',
        'attrClass' : 'btn-success',
        'handler'   : function() {
          var text = $('textarea[name="text"]').val();
          if (!text) {
            $('#modal-error').modal('hide');
          }
          $.send('/report_bug', {text: text}, function() {
            Confirm('Спасибо! Информация отправлена.', function(){});
            $('#modal-error').modal('hide');
          });
        }
      },
      {
        'text'      : 'Отмена',
        'attrClass' : 'btn-danger',
        'handler'   : function() {
          $('#modal-error').modal('hide');
        }
      }
    ]
  });
});

/**
 * Проверка статуса услуги и уведомление, если отключена за неуплату
 * @author Yury Lugovoy
 */
function checkStatus() {
  if ( ! location.hostname.match(/^vo\./)) {
    return;
  }
  $.send('/service_status', {}, function(result) {
    if (result.status_id == '2') {
      if (!$('#no-pay-modal').length) {
        makeModal({
          id: '#no-pay-modal',
          messages: 'Услуга в статусе "Отключена за неуплату". Вам необходимо погасить задолженность по счетам.'
        });
        setInterval(function() {
          if (!$('.no-pay-background').length) {
            $('body').append('<div class="no-pay-background"></div>');
          }
        }, 100);
      }
    }
  });
};