function changeTarif(self) {
  var action = $(self).data('action');
  $(self).loading();
  $.send(action, {}, function(result){
    var message = 'Назначена смена тарифа.';
    if (result.account_id) {
      message += ' Вам необходимо оплатить <a target="_blank" href="https://stat.profintel.ru/docs/printer/sch/' + result.account_id + '">счет #' + result.account_id + '</a>';
    }
    else {
      message += ' В течение 15 минут изменения вступят в силу';
    }
    Alert(message);
  });
  return false;
}
