$(document).ready(function () {
  if (window.location.pathname == '/monitoring/show') {
    load_now_calls();

    setInterval(function () {
      load_now_calls();
    }, 5000);
  }
});

function load_now_calls() {
  $.ajax({
    type    : 'GET',
    dataType: 'json',
    url     : '/monitoring/checkCalls',
    success : function (data) {
      $(".information_call").addClass('hide');
      $('.information_call.in_call_success').removeClass('in_call_success');

      if (data.result.length) {
        var call_info,
            row_call;
        for (var i in data.result) {
          call_info = data.result[i];

          if (call_info[0]['call'] === 'out') {
            row_call = $(".row_call[data-emp_number=" + call_info[2]['src_a'] + "]");
            if (row_call) {
              row_call.find('.information_call').removeClass('hide');
              row_call.find('.number_call').html(call_info[6]['dst']);
              row_call.find('.type_call').html('Исходящий');
            }
          } else if (call_info[0]['call'] === 'in') {
            row_call = $(".row_call[data-emp_number=" + call_info[3]['dst'] + "]");
            if (row_call) {
              row_call.find('.information_call').removeClass('hide');
              row_call.find('.number_call').html(call_info[2]['src']);
              row_call.find('.type_call').html('Входящий');
            }
          } else if (call_info[0]['call'] === 'spy') {
            row_call = $(".row_call[data-emp_number=" + call_info[5]['dst'] + "]");
            if (row_call) {
              row_call.find('.information_call.glyphicon').addClass('in_call_success');
            }
          }
        }
      }
    }
  });
}

function show_access_monitoring(emp_id, groups, $emps) {
  var form = $("#emp_access-dialog-form");

  $("#emp_access-groups option:selected").removeAttr("selected");
  $("#emp_access-emps option:selected").removeAttr("selected");

  if (groups) {
    groups.split(',').forEach(function (i, m) {
      $("#emp_access-groups option[value=" + i + "]").attr("selected", 'selected')
    });
  }

  if ($emps) {
    $emps.split(',').forEach(function (i, m) {
      $("#emp_access-emps option[value=" + i + "]").attr("selected", 'selected')
    });
  }

  makeModal({
    id: '#emp_access-dialog-modal',
    messages: $('<div>').append(form.removeClass('hide')).html(),
    buttons: [{
      text: 'Сохранить', handler: function () {
        save_monitoring_employer(emp_id);
        $('#emp_access-dialog-modal').modal('hide');
        return false;
      }
    }],
    events: [
      {
        name: 'show.bs.modal', func: function () {
        $('.float-menu').remove();

        $("#emp_access-groups+button:last").remove();
        $("#emp_access-emps+button:last").remove();
        return false;
      }
      },
      {
        name: 'hidden.bs.modal', func: function () {
        $('body').append(form);
        $('#emp_access-dialog-modal').remove();
        return false;
      }
      }
    ]
  });
}

function save_monitoring_employer(emp_id) {
  var data = {
    'emp_id': emp_id,
    'emp_groups': $("#emp_access-groups").val(),
    'emp_emps': $("#emp_access-emps").val(),
  };

  $.send('/monitoring/save', data, function (result) {
    location.reload();
  });
}


$("#change_service_in_phone").on('click', function () {
  var form = $("#change_service_in_phone-dialog-form");

  makeModal({
    id: '#change_service_in_phone-dialog-modal',
    messages: $('<div>').append(form.removeClass('hide')).html(),
    buttons: [{
      text: 'Сохранить', handler: function () {
        save_service_in_phone_admin();
        $('#change_service_in_phone-dialog-modal').modal('hide');
        return false;
      }
    }],
    events: [
      {
        name: 'show.bs.modal', func: function () {
        $('.float-menu').remove();

        $("#admin_number_select+span:last").remove();
        return false;
      }
      },
      {
        name: 'hidden.bs.modal', func: function () {
        $('body').append(form);
        $('#change_service_in_phone-dialog-modal').remove();
        return false;
      }
      }
    ]
  });
});

function save_service_in_phone_admin() {
  var data = {
    'number': $("#admin_number_select").val()
  };

  $.send('/monitoring/saveInNumber', data, function (result) {
    location.reload();
  });
}

function payback_spy(self) {
  var $this = $(self),
    number = $this.data('phone'),
    spy = $this.data('spy');
  if (!$this.hasClass('glyphicon-earphone') || $this.hasClass('in_call_success')) {
    return false;
  }

  if (!spy) {
    Alert('У Вас нет внутреннего номера. <a onclick=\'$("#change_service_in_phone").click(); return false;\' style="cursor: pointer;">Выбрать номер</a>');
    return false;
  }

  var data = {
    'number': number
  };

  $this.removeClass('glyphicon-earphone');
  $this.children('img').show()

  $.send('/monitoring/payback', data, function (result) {
    setTimeout(function () {
      $this.addClass('glyphicon-earphone');
      $this.children('img').hide();
      $this.addClass('in_call_success');
    }, 1000)
  });
}