// Переменная для хранения данных для детализации загруженных звонков
var statisticDetailedCallsData;

Chart.defaults.global.pointHitDetectionRadius = 1;

/**
 * Преобразование datetime (ГГГГ-ММ-ДД ЧЧ:ММ:СС) в формат
 *
 * ЧЧ:ММ:СС
 * <small>ДД месяц ГГГГ</small>
 */
function statisticDetailedFormatDatetime(dt) {
  var monthes = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

  dt = dt.replace(/\s/, '-').split('-');
  dt = dt[3] + '<br><small>' + dt[2] + ' ' + monthes[parseInt(dt[1]) - 1] + ' ' + dt[0] + '</small>';

  return dt;
}

/**
 * Детализация - Генерация постраничной навигации
 */
function statisticDetailedGeneratePagination(data) {
  var pages_count = Math.ceil(parseInt(data.count) / parseInt(data.limit));
  var pages = [];
  var page  = parseInt(data.page);
  if (page > 1) {
    pages.push('<a onclick="statisticDetailedGet($(this))" style="cursor: pointer;" class="link_dotted" data-page="' + (page - 1) + '">«</a>');
  }
  pages.push('<a onclick="statisticDetailedGet($(this))" style="cursor: pointer;" class="' + (page == 1 ? 'black' : 'link_dotted') + '" data-page="1">1</a>');
  if (page > 3) {
    pages.push('<span>...</span>');
  }
  if (pages_count > 1) {
    var page_from = (page < 3 ? 2 : page - 1);
    var page_to   = (page < pages_count ? page + 1 : pages_count);
    for (var i = page_from; i <= page_to; i++) {
      pages.push('<a onclick="statisticDetailedGet($(this))" style="cursor: pointer;" class="' + (page == i ? 'black' : 'link_dotted') + '" data-page="' + i + '">' + i + '</a>');
    }
  }
  if (page < pages_count - 2) {
    pages.push('<span>...</span>');
  }
  if (page < pages_count - 1) {
    pages.push('<a onclick="statisticDetailedGet($(this))" style="cursor: pointer;" class="link_dotted" data-page="' + pages_count + '">' + pages_count + '</a>');
  }
  if (page < pages_count) {
    pages.push('<a onclick="statisticDetailedGet($(this))" style="cursor: pointer;" class="link_dotted" data-page="' + (page + 1) + '">»</a>');
  }
  pages = '<li>' + pages.join('</li><li>') + '</li>';

  return pages;
}

/**
 * Детализация - Скачивание файла записи разговора
 */
function statisticDetailedDownloadFile(file) {
  window.open('/statistic/records/download?file=' + file);
}

/**
 * Детализация - Скачивание архива нескольких записей разговоров
 */
function statisticDetailedDownloadSeveralFiles() {
  var files = [];

  $('.checked > .statistic_detailed_file').each(function() {
    files.push($(this).val());
  });

  if (!files.length) {
    Alert('Не выбраны файлы для скачивания');
    return false;
  }

  if (files.length) {
    window.open('/statistic/records/download/several?files=' + files.join(','));
  }
}

/*
 * Инициализация плеера
 * Используется пример http://jplayer.org/latest/demo-07/
 */
function statisticDetailedInitJPlayer(el, mediaObjects){
  var myPlayer = $(el),
    myPlayerData,
    fixFlash_mp4, // Flag: The m4a and m4v Flash player gives some old currentTime values when changed.
    fixFlash_mp4_id, // Timeout ID used with fixFlash_mp4
    ignore_timeupdate, // Flag used with fixFlash_mp4
    options = {
      ready: function (event) {
        // Hide the volume slider on mobile browsers. ie., They have no effect.
        if(event.jPlayer.status.noVolume) {
          // Add a class and then CSS rules deal with it.
          $(".jp-gui").addClass("jp-no-volume");
        }
        // Determine if Flash is being used and the mp4 media type is supplied. BTW, Supplying both mp3 and mp4 is pointless.
        fixFlash_mp4 = event.jPlayer.flash.used && /m4a|m4v/.test(event.jPlayer.options.supplied);
        // Setup the player with media.
        $(this).jPlayer("setMedia", mediaObjects);
      },
      timeupdate: function(event) {
        if(!ignore_timeupdate) {
          myControl.progress.slider("value", event.jPlayer.status.currentPercentAbsolute);
        }
      },
      volumechange: function(event) {
        if(event.jPlayer.options.muted) {
          myControl.volume.slider("value", 0);
        } else {
          myControl.volume.slider("value", event.jPlayer.options.volume);
        }
      },
      swfPath: "../js",
      supplied: "mp3, m4a, oga, wav",
      cssSelectorAncestor: "#jp_container",
      wmode: "window",
      keyEnabled: true
    },
    myControl = {
      progress: $(options.cssSelectorAncestor + " .jp-progress-slider"),
      volume: $(options.cssSelectorAncestor + " .jp-volume-slider")
    };
  // Instance jPlayer
  myPlayer.jPlayer(options);
  // A pointer to the jPlayer data object
  myPlayerData = myPlayer.data("jPlayer");
  // Create the progress slider control
  // README: при init не работает - использовать в statisticDetailedShowRecordFormModal()
  // myControl.progress.slider({
  //   animate: "fast",
  //   max: 100,
  //   range: "min",
  //   step: 0.1,
  //   value : 0,
  //   slide: function(event, ui) {
  //     var sp = myPlayerData.status.seekPercent;
  //     if(sp > 0) {
  //       // Apply a fix to mp4 formats when the Flash is used.
  //       if(fixFlash_mp4) {
  //         ignore_timeupdate = true;
  //         clearTimeout(fixFlash_mp4_id);
  //         fixFlash_mp4_id = setTimeout(function() {
  //           ignore_timeupdate = false;
  //         },1000);
  //       }
  //       // Move the play-head to the value and factor in the seek percent.
  //       myPlayer.jPlayer("playHead", ui.value * (100 / sp));
  //     } else {
  //       // Create a timeout to reset this slider to zero.
  //       setTimeout(function() {
  //         myControl.progress.slider("value", 0);
  //       }, 0);
  //     }
  //   }
  // });
  // Create the volume slider control
  myControl.volume.slider({
    animate: "fast",
    max: 1,
    range: "min",
    step: 0.01,
    value : $.jPlayer.prototype.options.volume,
    slide: function(event, ui) {
      myPlayer.jPlayer("option", "muted", false);
      myPlayer.jPlayer("option", "volume", ui.value);
    }
  });
}

/**
 * Формирует и открывает окно с детализацией звонка
 *
 * @author Kirill Vertiporokh
 */
function statisticDetailedCalls(group_id) {
  if (statisticDetailedCallsData[group_id]) {
    var directions = $('.statistic-detailed-table tbody').data('directions');

    var html = [], obj, row;
    $.each(statisticDetailedCallsData[group_id], function() {
      obj = $(this)[0];
      

      var dt  = statisticDetailedFormatDatetime(obj.dt_begin);
      var src = obj.group_number_a + (obj.group_src_a && obj.group_src_a != obj.group_number_a ? ' / ' + obj.group_src_a : '');
      var dst = (obj.number_outer ? obj.number_outer : obj.group_number_b) +
                  (obj.number_outer || (obj.group_src_b && obj.group_src_b.length && obj.group_src_b != obj.group_number_b) || ((!obj.group_src_a || !obj.group_src_a.length) && (!obj.group_src_b || !obj.group_src_b.length) && obj.group_number_b && obj.group_number_b.length == 11) ?
                    ' / ' + (obj.src_b && obj.src_b.length && obj.src_b != obj.group_number_b ? obj.src_b : obj.group_number_b) :
                    ''
                  );

      row = [
        statisticDetailedFormatDatetime(obj.dt_begin),
        '<span class="icon icon-' + directions[obj.direction_id]['icon'] + '" title="' + directions[obj.direction_id]['title'] + '"></span>',
        src,
        dst,
        obj.time_wait,
        obj.time_talk
      ];

      html.push('<td>' + row.join('</td><td>') + '</td>');
    });

    var modalBody = '<table class="table table-no-bordered m-b-lg">'+
      '<thead><tr>'+
          '<th>Дата звонка<br/><small class="text-muted">Время / Дата</small></th>'+
          '<th></th>' +
          '<th>Вызывающий<br/><small class="text-muted">Внешний / Внутренний</small></th>'+
          '<th>Вызываемый<br/><small class="text-muted">Внешний / Внутренний</small></th>'+
          '<th>Ожидание<br/><small class="text-muted">в секундах</small></th>' +
          '<th>Разговор<br/><small class="text-muted">в секундах</small></th>' +
      '</tr></thead>'+
      '<tr>' + html.join('</tr><tr>') + '</tr>' +
    '</table>';
  } else {
    var modalBody = 'Данные детализации отсутствуют';
  }

  makeModal({
    id               : "#jquery_detailed_modal",
    title            : "",
    modalDialogClass : 'w-800',
    messages         : modalBody,
    buttons          : false
  });
}

/**
* Формирует и открывает модальное окно для прослушивания записи разговора
* @params: options{     - массив с настройками
*            params:[]  - параметры таблицы с данными записи
*            audio:''   - путь к записи разговора
*          }          
*/
function statisticDetailedShowRecordFormModal(options){
  var modalBody = '<table class="table table-no-bordered m-b-lg" id="table_madol_info_audio">'+
    '<thead><tr>'+
        '<th>Дата звонка<br/><small class="text-muted">Время / Дата</small></th>'+
        '<th>Вызывающий<br/><small class="text-muted">Внешний / Внутренний</small></th>'+
        '<th>Вызываемый<br/><small class="text-muted">Внешний / Внутренний</small></th>'+
    '</tr></thead>'+
    '<tr>'+
      '<td>'+options.params[0]+'</td>'+
      '<td>'+options.params[1]+'</td>'+
      '<td>'+options.params[2]+'</td>'+
    '</tr>'+
  '</table>'+
  '<div id="jquery_jplayer_modal_body"></div>';
  makeModal({
    id               : "#jquery_jplayer_modal",
    title            : "",
    modalDialogClass : 'w-600',
    messages         : modalBody,
    buttons          : false,
    events           : [
      {name:"show.bs.modal",func:function(){
        $("#jquery_jplayer_tpl > div").appendTo("#jquery_jplayer_modal_body");
      }},
      {name:"shown.bs.modal",func:function() {
        var myPlayer = $("#jquery_jplayer");
        myPlayer.jPlayer("setMedia",{wav: options.audio}).jPlayer("play");

        var Progress = $('#jp_container .jp-progress-slider');
        Progress.slider({
          animate : "fast",
          max     : 100,
          range   : "min",
          step    : 0.1,
          value   : 0,
          slide   : function(event, ui) {
            if (myPlayer.data('jPlayer').status.currentTime == 0) {
              return false;
            }
            myPlayer.jPlayer("playHead", ui.value);
          }
        })
      }},
      {name:"hide.bs.modal",func:function() {
        var myPlayer = $("#jquery_jplayer");
        myPlayer.unbind($.jPlayer.event.ended)
        $('#jquery_jplayer_modal').unbind('hide.bs.modal');
        $("#jquery_jplayer_modal_body > div").appendTo("#jquery_jplayer_tpl");
        $("#jquery_jplayer").jPlayer("clearMedia");
      }}
    ],
  })
}

function statisticDetailedPlayFile(file, obj) {
  var tr_obj = obj.closest('tr');
  obj = obj.parent().parent();

  $('#jquery_jplayer_tpl').data('file', file);
  var myPlayer = $("#jquery_jplayer");
  myPlayer.on($.jPlayer.event.ended, function () {
    var tr_obj_next = tr_obj.next('tr');
    var tr_play_audio_button = tr_obj_next.find('.tr_play_audio_button');
    if (tr_obj_next.length) {
      var table_tr2 = $("#table_madol_info_audio").find('tr').eq(1);
      table_tr2.find('td').eq(0).html(tr_play_audio_button.parent().parent().find('td:eq(1)').html());
      table_tr2.find('td').eq(1).html(tr_play_audio_button.parent().parent().find('td:eq(3)').html());
      table_tr2.find('td').eq(2).html(tr_play_audio_button.parent().parent().find('td:eq(4)').html());
      $('#jquery_jplayer_tpl').data('file', tr_play_audio_button.data('file'));
      myPlayer.jPlayer("setMedia", {
        // wav: '/vo/test.wav'
        wav: '/statistic/records/download?file=' + tr_play_audio_button.data('file')
      }).jPlayer("play");
      tr_obj = tr_obj_next;
    }
  });

  statisticDetailedShowRecordFormModal({
    params : [obj.find('td:eq(1)').html(), obj.find('td:eq(3)').html(), obj.find('td:eq(4)').html()],
    audio  : '/statistic/records/download?file=' + file
    // audio  : '/vo/test.wav'
  });
}

/**
 * Детализация - удаление файла записи разговоров
 */
function statisticDetailedDeleteFile(file, obj) {
  Confirm('Удалить файл?', function() {
    $.send('/statistic/records/delete', {
      file: file
    }, function() {
      Alert('Файл удален!');
      $('.statistic_detailed_send').click();
    });
  });
}

/**
 * Детализация - удаление нескольких файлов записей разговоров
 */
function statisticDetailedDeleteSeveralFiles() {
  var files = [];

  $('.checked > .statistic_detailed_file').each(function() {
    files.push($(this).val());
  });

  if (!files.length) {
    Alert('Не выбраны файлы для удаления');
    return false;
  }

  Confirm('Удалить выбранные файлы?', function() {
    if (files.length) {
      $.send('/statistic/records/delete/several', {
        files: files
      }, function() {
        $('.statistic_detailed_send').click();
      });
    }
  });
}

/**
 * Детализация - удаление шаблона
 */
function statisticDeleteTemplate() {
  Confirm('Удалить шаблон?', function() {
    $.send('/statistic/templates/delete', {
      template_id: $('#statistic_template').val()
    }, function() {
      location.reload();
    })
  });
}

function statisticGetStatistic(obj, template_add) {
  var template_add = template_add || false;

  var directions = [];
  var statuses   = [];
  $('.checked > input[name^=statistic_directions]').each(function() {  directions.push($(this).val());  });
  $('.checked > input[name^=statistic_statuses]'  ).each(function() {  statuses.push(  $(this).val());  });

  var template = '';
  if (template_add) {
    Prompt({
      title   : 'Добавление шаблона',
      message : 'Введите название шаблона'
    }).done(function (template) {

      if (!template.trim()) {
        return false;
      }

      if (obj.loading('warning')) {
        return false;
      }

      $.send('/statistic/' + (template_add ? 'templates/add' : 'get'), {
        template    : template,
        period_from : $('#statistic_period_from').val(),
        period_to   : $('#statistic_period_to').val(),
        phone       : $('#statistic_phone option[selected=selected]').attr('value'),
        employers   : $('select[name^=statistic_employers]').val(),
        groups      : $('select[name^=statistic_groups]').val(),
        grouping    : $('#statistic_grouping').val(),
        comparing   : $('#statistic_comparing option[selected=selected]').attr('value'),
        statuses    : statuses,
        directions  : directions
      }, function (result) {
        location.reload();
      });
    });

    return false;
  }

  if (obj.loading('warning')) {
    return false;
  }

  $.send('/statistic/' + (template_add ? 'templates/add' : 'get'), {
    template    : template,
    period_from : $('#statistic_period_from').val(),
    period_to   : $('#statistic_period_to').val(),
    phone       : $('#statistic_phone option[selected=selected]').attr('value'),
    employers   : $('select[name^=statistic_employers]').val(),
    groups      : $('select[name^=statistic_groups]').val(),
    grouping    : $('#statistic_grouping').val(),
    comparing   : $('#statistic_comparing option[selected=selected]').attr('value'),
    statuses    : statuses,
    directions  : directions
  }, function (result) {
    if (template_add) {
      location.reload();
      return false;
    }

    // Средние показатели
    var html = [];
    $.each(result.avg, function() {
      var obj = $(this)[0];
      var row = [
        '<b>' + (obj.name          ? obj.name          : 'Всего') + '</b>',
                (obj.cnt           ? obj.cnt           :       0),
                (obj.avg_time_talk ? obj.avg_time_talk :       0),
                (obj.avg_time_wait ? obj.avg_time_wait :       0)
      ];
      html.push('<td>' + row.join('</td><td>') + '<td>');
    });

    $('.statistic-avg').html('<tr>' + html.join('</tr><tr>') + '</tr>');
    $('.chart_statistic_grouping').text($('#statistic_grouping > option:selected').text());
    if ($('#statistic_comparing').val()) {
      $('.chart_statistic_comparing').text($('#statistic_comparing > option:selected').text());
      $('.chart_statistic_comparing_text').removeClass('hide');
    } else {
      $('.chart_statistic_comparing').text('');
      $('.chart_statistic_comparing_text').addClass('hide');
    }

    $('.statistic_chart').removeClass('hide');

    // Непосредственно график
    var labels = [];
    var data   = {};
    var sort   = {};
    var type   = 'bar';
    var legend = false;
    $.each(result.result, function() {
      var obj = $(this)[0];
      if (!sort[obj.grouping]) {
        sort[obj.grouping] = labels.length;
        labels.push(obj.group);
      }
      if (!obj.compare) {
        if (!data[0]) {
          data[0] = {};
        }
        data[0][sort[obj.grouping]] = {cnt: obj.cnt};
      } else {
        legend = true;
        if (!data[obj.comparing]) {
          data[obj.comparing] = {};
        }
        data[obj.comparing][sort[obj.grouping]] = {label: obj.compare, cnt: obj.cnt};
      }
    });

    // Замена необходима, иначе происходит наложение
    $('canvas#statistic-chart').replaceWith('<canvas id="statistic-chart" height="400" width="799"></canvas>');
    $('#legendCanvas').html('');

    var chart = $('canvas#statistic-chart');
    if (!chart) {
      return;
    }
    var ctx = chart.get(0).getContext("2d");

    var chartData;
    // Если данные лишь сгруппированы
    if (type == 'doughnut') {
      chartData = [];
      var i = 0;
      $.each(labels, function (index, value) {
        chartData.push({
          label     : value,
          value     : data[index],
          color     : ChartColors.defaults[i],
          highlight : ChartColors.hover[i]
        });
        i = (i == ChartColors.defaults.length - 1 ? 0 : i + 1);
      });

      $('#legendCanvas').html(new Chart(ctx).Doughnut(chartData, {
        responsive            : true,
        tooltipFontColor      : 'rgb(59, 59, 59)',
        tooltipTitleFontColor : 'rgb(59, 59, 59)',
        tooltipFillColor      : 'rgb(253, 206, 88)',
        tooltipFontFamily     : "'BlissPro-Extrabold', 'Roboto', Arial, sans-serif",
        multiTooltipTemplate  : "<%=datasetLabel %>: <%= value %>",
        legendTemplate        : "<ul class=\"<%=name.toLowerCase()%>-legend legend list-inline\">"+
          "<% for (var i=0; i<segments.length; i++){%>"+
            "<li>"+
              "<div class=\"legend-color\" style=\"background-color:<%=segments[i].fillColor%>\"></div>"+
              "<div class=\"legend-title\"></div><%if(segments[i].label){%><%=segments[i].label%><%}%>"+
            "</li>"+
          "<%}%>"+
          "</ul>"
      }).generateLegend());

    // Для данных с детализацией
    } else {
      chartData = {labels: $.map(labels, function (value) {
        return [value];
      }), datasets: []};
      var i = 0;
      $.each(data, function (index, value) {
        $.each(labels, function (key) {
          if (!value[key]) {
            value[key] = null;
          }
        });
        var label;
        for (var k in value) {
          if (value[k]) {
            label = value[k].label;
          }
        }
        chartData.datasets.push({
          label           : label,
          fillColor       : ChartColors.defaults[i],
          strokeColor     : ChartColors.defaults[i],
          highlightFill   : ChartColors.hover[i],
          highlightStroke : ChartColors.hover[i],
          data            : $.map(value, function (value) {
            return [value && value.cnt ? value.cnt : null];
          })
        });

        i = (i == ChartColors.defaults.length - 1 ? 0 : i + 1);
      });

      var html = new Chart(ctx).Bar(chartData, {
        responsive            : true,
        barStrokeWidth        : 10,
        barShowStroke         : true,
        scaleLineWidth        : 1,
        tooltipFontColor      : 'rgb(59, 59, 59)',
        tooltipTitleFontColor : 'rgb(59, 59, 59)',
        tooltipFillColor      : 'rgb(253, 206, 88)',
        tooltipFontFamily     : "'BlissPro-Extrabold', 'Roboto', Arial, sans-serif",
        multiTooltipTemplate  : "<%=datasetLabel %>: <%= value %>",
        legendTemplate        : "<ul class=\"<%=name.toLowerCase()%>-legend legend list-inline\">"+
          "<% for (var i=0; i<datasets.length; i++){%>"+
            "<li>"+
              "<div class=\"legend-color\" style=\"background-color:<%=datasets[i].fillColor%>\"></div>"+
              "<div class=\"legend-title\"></div><%if(datasets[i].label){%><%=datasets[i].label%><%}%>"+
            "</li>"+
          "<%}%>"+
          "</ul>"
      });

      if (legend) {
        html = html.generateLegend();
      }

      $('#legendCanvas').html(html);
    }
  });
}

function statisticAddTemplate(obj) {
  statisticGetStatistic(obj, true);
}

function statisticDetailedGet(obj, type) {
  if (obj.hasClass('black') || $('.statistic_detailed_send').loading('warning')) {
    return false;
  }

  var directions = [];
  var statuses   = [];
  $('.checked > input[name^=statistic_detailed_directions]').each(function() {  directions.push($(this).val());  });
  $('.checked > input[name^=statistic_detailed_statuses]'  ).each(function() {  statuses.push(  $(this).val());  });

  // Установка активности для выбранной ссылки в блоке "Показывать по"
  if (obj.parent().parent().hasClass('statistic-detailed-limit')) {
    $('ul.statistic-detailed-limit > li > a').removeClass('black').addClass('link_dotted');
    obj.removeClass('link_dotted').addClass('black');
  }
  // Установка активности для выбранной ссылки в блоках "Страницы"
  if (obj.parent().parent().hasClass('statistic-detailed-page')) {
    $('ul.statistic-detailed-page > li > a').removeClass('black').addClass('link_dotted');
    obj.removeClass('link_dotted').addClass('black');
  }

  // README: после объединения детализации и записей разговоров - убрать
  var only_records = $('.container_content').data('records');

  var url = '/statistic/' + (only_records ? 'records' : 'detailed') + '/get';

  var params = {
    type                 : type,
    period_from          : $('#statistic_detailed_period_from').val(),
    period_to            : $('#statistic_detailed_period_to').val(),
    duration_compare     : $('#statistic_detailed_duration_compare').val(),
    duration             : $('#statistic_detailed_duration').val(),
    statuses             : statuses,
    directions           : directions,
    phones               : $('select[name^=statistic_detailed_phones]').val(),
    phones_emp           : $('select[name^=statistic_detailed_phones_emp]').val(),
    page                 : $('ul.statistic-detailed-page > li > a.black').data('page'),
    limit                : $('ul.statistic-detailed-limit > li > a.black').data('limit')
  };

  if (type == 'excel') {
    window.open(url + '?' + $.param(params));
    return false;
  }

  $.send(url, params, function (data) {
    // Очередная установка активности для выбранных ссылок в блоках "Показывать по" и "Страницы"
    $('ul.statistic-detailed-limit > li > a').removeClass('black').addClass('link_dotted');
    $('ul.statistic-detailed-limit > li > a[data-limit=' + data.limit + ']').removeClass('link_dotted').addClass('black');

    // Очистка и последующая генерация постраничной навигации
    $('ul.statistic-detailed-page').each(function() {
      $(this).find('li:gt(0)').remove();
    });
    $('ul.statistic-detailed-page').append(statisticDetailedGeneratePagination(data));

    var html = [], row, obj;

    // Данные для детализации звонков
    if (!only_records) {
      statisticDetailedCallsData = {};
      $.each(data.detailed, function() {
        obj = $(this)[0];
        if (!statisticDetailedCallsData[obj.group_id]) {
          statisticDetailedCallsData[obj.group_id] = [];
        }

        statisticDetailedCallsData[obj.group_id].push(obj);
      });
    }

    var directions = $('.statistic-detailed-table tbody').data('directions');
    var popover = [
      'data-toggle="popover"',
      'data-trigger="hover"',
      'data-placement="left"',
      'data-content="Тест"'
    ];
    // Генерация HTML для результатов
    $.each(data.result, function() {
      obj = $(this)[0];
      var dt  = statisticDetailedFormatDatetime(obj.dt_begin);
      var src = obj.group_number_a + (obj.group_src_a && obj.group_src_a != obj.group_number_a ? ' / ' + obj.group_src_a : '');
      var dst = (obj.number_outer ? obj.number_outer : obj.group_number_b) +
                  (obj.number_outer || (obj.group_src_b && obj.group_src_b.length && obj.group_src_b != obj.group_number_b) || ((!obj.group_src_a || !obj.group_src_a.length) && (!obj.group_src_b || !obj.group_src_b.length) && obj.group_number_b && obj.group_number_b.length == 11) ?
                    ' / ' + (obj.group_src_b && obj.group_src_b.length && obj.group_src_b != obj.group_number_b ? obj.group_src_b : obj.group_number_b) :
                    ''
                  );

      row = [
        // README: для соединения детализации и записей разговоров
        // (obj.record_file ?
        //   '<input type="checkbox" name="statistic_detailed_download[]" value="' + obj.record_file + '">' :
        //   '<span class="icon icon-remove-circle-black m-r" title="Нет записи для данного разговора"></span>'
        // ),
        dt,
        '<span class="icon icon-' + directions[obj.direction_id]['icon'] + '" title="' + directions[obj.direction_id]['title'] + '"></span>',
        src,
        dst];
      if (!only_records) {
        row.push(obj.time_wait);
      }
      row.push(obj.time_talk);
        // README: для соединения детализации и записей разговоров
        // (obj.record_file ? '<a href="javascript:statisticDetailedDownloadFile(\'' + obj.record_file + '\');" class="icon icon-upload m-r-lg"       title="Скачать запись"></a>' +
        //                    '<a href="javascript:statisticDetailedPlayFile(\''     + obj.record_file + '\');" class="icon icon-play m-r-lg"         title="Воспроизвести"></a>'   +
        //                    '<a href="javascript:statisticDetailedDeleteFile(\''   + obj.record_file + '\');" class="icon icon-remove-circle-black" title="Удалить"></a>' : '')
      // ];
      if (only_records) {
        if (typeof obj.have_record !== 'undefined' && obj.have_record === 't') {
          row.unshift('<input type="checkbox" class="statistic_detailed_file" value="' + obj.record_file + '">');

          row.push(
            '<a href="javascript:statisticDetailedDownloadFile(\'' + obj.record_file + '\');"          class="icon icon-upload m-r-lg tr_download_audio_button"                               title="Скачать запись"></a>' +
            '<a data-file="' + obj.record_file + '" onclick="statisticDetailedPlayFile(\''             + obj.record_file + '\', $(this));" class="icon icon-play m-r-lg tr_play_audio_button"         style="cursor:pointer;" title="Воспроизвести"></a>'  +
            '<a href="javascript:statisticDetailedDeleteFile(\''   + obj.record_file + '\');"          class="icon icon-remove-circle-black tr_remove_audio_button"                         title="Удалить"></a>'
          );
        } else {
          row.unshift('');

          row.push(
            '<a href="javascript:statisticDetailedDeleteFile(\''   + obj.record_file + '\');"          class="icon icon-remove-circle-black"                         title="Удалить"></a>'
          );
        }
      }

      html.push('<tr onclick="statisticDetailedCalls(' + obj.group_id + ')" style="cursor: pointer;"><td>' + row.join('</td><td>') + '</td></tr>');
    });

    $('.statistic-detailed-result tbody').html(html.join(''));
    $('.statistic-detailed-result tbody tr').each(function() {
      $(this).find('td:gt(' + (only_records ? '4' : '3') + '):lt(7)').addClass('text-right');
      if ($(this).find('td:first-child input[type=checkbox]').length) {
        $(this).find('td:gt(4):lt(7)').addClass('container-nohover').css('width', (only_records ? 153 : 'auto'));
        $(this).find('td:last-child').addClass('container-hover').attr('colspan', 2);
      }
      if (!only_records) {
        $(this).popover({
          content   : 'Нажмите для детализации',
          delay     : {show: 600, hide: 50},
          placement : 'top',
          trigger   : 'hover'
        });
      }
    });

    /**
     * README
     * Необходима для динамической генерации элементов
     */
    inputRadioCheckboxStyled();

    $('.statistic-detailed-result').removeClass('hide');
  });
}

$(function() {
  /**
   * Статистика
   */
  $('#statistic_template').on('change', function() {
    var params = $(this).find('option[value=' + $(this).val() + ']').data('params');

    if (params.phone    )  {  $('#statistic_phone').val(params.phone).selectmenu('refresh');                        }
    if (params.employers)  {  $('select[name^=statistic_employers]').val(params.employers).multiselect('refresh');  }
    if (params.groups   )  {  $('select[name^=statistic_groups]').val(params.groups).multiselect('refresh');        }
    if (params.grouping )  {  $('#statistic_grouping').val(params.grouping).selectmenu('refresh');                  }
    if (params.comparing)  {  $('#statistic_comparing').val(params.comparing).selectmenu('refresh');                }
    $('input[name^=statistic_statuses]:checked').icheck('toggle');
    if (params.state    )  {  $.each(params.state,     function (key, value)  {  $('input[name^=statistic_statuses][value='   + value + ']').icheck('toggle');  });  }
    $('input[name^=statistic_directions]:checked').icheck('toggle');
    if (params.direction)  {  $.each(params.direction, function (key, value)  {  $('input[name^=statistic_directions][value=' + value + ']').icheck('toggle');  });  }
  });

  $('#statistic_period_from').on('change', function() {  $('.statistic_period_from').text($(this).val());  });
  $('#statistic_period_to'  ).on('change', function() {  $('.statistic_period_to'  ).text($(this).val());  });

  $('#statistic_grouping').on('change', function() {
    var group = $(this).children(':selected').attr('group');

    if ($('#statistic_comparing').val() && $('#statistic_comparing [group=' + group + '][value=' + $('#statistic_comparing').val() + ']').length) {
      $('#statistic_comparing').val('');
    }

    $('#statistic_comparing [group!=' + group + ']').removeAttr('disabled');
    $('#statistic_comparing [group='  + group + ']').attr('disabled', 'disabled');
    
    $('#statistic_comparing').selectmenu('refresh');
  });

  /**
   * Детализация
   */
  if ($(document).find('#jquery_jplayer')){
    statisticDetailedInitJPlayer("#jquery_jplayer", {});
  }

});