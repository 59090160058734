function blackListHandler() {
  if ( !$('#blacklist-block').length ) {
    return false;
  }
  $('#blacklist-form').submit(function(e) {
    e.preventDefault();
    $('#send-form').loading();
    $.send(e.target.action, {
      'number' : $('input[name=number]').val(),
      'reason' : $('input[name=reason]').val()
    }, function(result) {
      if (!result.length) {
        location.href = location.href;
      }
    });
  });

  $('.blackphone-delete').click(function(e) {
    $.send('black_list/delete/' + $(this).data('id'), {}, function(result) {
      if (!result.length) {
        $(e.target).parents('tr').remove();
      }
    });
  });
}